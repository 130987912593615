import type { NavigationGuardNext, RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import type { Route } from "@sentry/vue/types/router";
import { getValidatedAccessToken } from "@/utils/commands";

const requireAuth =
  () => async (_to: Route, _from: Route, next: NavigationGuardNext) => {
    await getValidatedAccessToken();
    return next();
  };

const routes = (): RouteRecordRaw[] => {
  const profile: RouteRecordRaw[] = [
    {
      path: "/profile-general",
      beforeEnter: requireAuth(),
      component: () => import("@/views/profile/ProfileGeneral.vue"),
    },
    {
      path: "/profile-change-password",
      beforeEnter: requireAuth(),
      component: () => import("@/views/profile/ProfileChangePassword.vue"),
    },
  ];
  const salesReports: RouteRecordRaw[] = [
    {
      path: "/sales-reports",
      beforeEnter: requireAuth(),
      component: () => import("@/views/sales_report/SalesReportsPage.vue"),
    },
    {
      path: "/create-sales-report/:temporary?",
      beforeEnter: requireAuth(),
      component: () => import("@/views/sales_report/CreateSalesReportPage.vue"),
      props: (routes) => ({
        temporary: routes.params.temporary === "temporary",
      }),
    },
    {
      path: "/sales-report/:id",
      beforeEnter: requireAuth(),
      component: () => import("@/views/sales_report/SalesReportDetailPage.vue"),
      props: true,
    },
    {
      path: "/customer-card-list",
      beforeEnter: requireAuth(),
      component: () => import("@/views/customer_card/CustomerCardListPage.vue"),
    },
    {
      path: "/create-customer-card",
      beforeEnter: requireAuth(),
      // route level code-splitting
      component: () =>
        import("@/views/customer_card/CreateCustomerCardPage.vue"),
    },
    {
      path: "/customer-card-detail/:id",
      beforeEnter: requireAuth(),
      component: () =>
        import("@/views/customer_card/CustomerCardDetailPage.vue"),
    },
  ];
  return [
    {
      path: "/",
      beforeEnter: requireAuth(),
      redirect: "/sales-reports",
    },
    {
      path: "/sign-in",
      component: () => import("@/views/login/LoginPage.vue"),
      meta: {
        layout: "simple",
      },
    },
    ...profile,
    ...salesReports,
  ];
};

export default createRouter({
  history: createWebHistory(),
  routes: routes(),
});
