import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/styles/style.scss";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import { ENVIRONMENT, SENTRY_DSN } from "@/constants/envs";
import * as Sentry from "@sentry/vue";

const pinia = createPinia();
pinia.use(piniaPersist);

const app = createApp(App);

if (SENTRY_DSN && ENVIRONMENT !== "local") {
  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [new Sentry.Replay()],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(ElementPlus).use(pinia).use(router).mount("#app");
