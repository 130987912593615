<template>
  <el-container class="aside-container">
    <el-row class="tac">
      <el-col :span="26">
        <el-menu class="el-menu-vertical-demo">
          <div v-if="route.path.includes('profile')">
            <div class="category">Profile</div>
            <el-menu-item
              :class="{
                'is-active': route.path.startsWith('/profile-general'),
              }"
              @click="routerPush('/profile-general')"
            >
              <el-icon size="30"><User /></el-icon>
              <span class="menu">General</span>
            </el-menu-item>
            <el-menu-item
              :class="{
                'is-active': route.path.startsWith('/profile-change-password'),
              }"
              @click="routerPush('/profile-change-password')"
            >
              <el-icon size="30"><Key /></el-icon>
              <span class="menu">Change Password</span>
            </el-menu-item>
          </div>

          <div v-else>
            <div class="category">Category</div>
            <el-menu-item
              v-if="authoritiesJson.some((v) => v.startsWith('SALES_'))"
              :class="{
                'is-active': route.path.startsWith('/sales-reports'),
              }"
              @click="routerPush('/sales-reports')"
            >
              <el-icon size="30"><Document /></el-icon>
              <span class="menu">Sales reports</span>
            </el-menu-item>
            <el-menu-item
              :class="{
                'is-active': route.path.startsWith('/customer-card-list'),
              }"
              @click="routerPush('/customer-card-list')"
            >
              <el-icon size="30"><Postcard /></el-icon>
              <span class="menu">Customer card</span>
            </el-menu-item>
          </div>
        </el-menu>
      </el-col>
    </el-row>
  </el-container>
</template>

<script setup lang="ts">
import { routerPush } from "@/utils/commands";
import { Document, Postcard, User, Key } from "@element-plus/icons-vue";
import { useUserStore } from "@/stores/user";
import { useRoute } from "vue-router";

const { authoritiesJson } = useUserStore();
const route = useRoute();
</script>
