import "element-plus/es/components/message/style/css";
import { ElMessage } from "element-plus";

export function toastSuccess(message: string, timer = 2_000) {
  alert(message, timer, "success");
}
export function toastError(message: string, timer = 10_000) {
  alert(message, timer, "error");
}
export function toastInfo(message: string, timer = 5_000) {
  alert(message, timer, "info");
}
export function toastWarning(message: string, timer = 5_000) {
  alert(message, timer, "warning");
}
function alert(
  message: string,
  timer: number,
  type: "success" | "warning" | "error" | "info"
) {
  ElMessage({
    type: type,
    message: message,
    duration: timer,
  });
}
