import { defineStore } from "pinia";
import jwt_decode from "jwt-decode";
import { getNewToken, signOut } from "@/utils/commands";
import type { LoggedInUser } from "@/definitions/types";
import type { AuthorityTypeString } from "@/definitions/authorities";
import { AuthorityType } from "@/definitions/authorities";

export const useUserStore = defineStore("user", {
  state: (): LoggedInUser => {
    return {
      id: 0,
      loginId: "",
      name: "",
      profileImageUrl: "",
      accessToken: "",
      authoritiesJson: [],
    };
  },
  getters: {
    user: (state: LoggedInUser): LoggedInUser => {
      return {
        id: state.id,
        loginId: state.loginId,
        name: state.name,
        profileImageUrl: state.profileImageUrl,
        accessToken: state.accessToken,
        authoritiesJson: state.authoritiesJson,
      };
    },
    HAS_CUSTOMER_CARD_CREATE: (state: LoggedInUser): boolean => {
      return state.authoritiesJson.includes(AuthorityType.CUSTOMER_CARD_CREATE);
    },
    HAS_CUSTOMER_CARD_EDIT: (state: LoggedInUser): boolean => {
      return state.authoritiesJson.includes(AuthorityType.CUSTOMER_CARD_EDIT);
    },
    HAS_CUSTOMER_CARD_DELETE: (state: LoggedInUser): boolean => {
      return state.authoritiesJson.includes(AuthorityType.CUSTOMER_CARD_DELETE);
    },
    HAS_SALES_REPORT_CREATE: (state: LoggedInUser): boolean => {
      return state.authoritiesJson.includes(AuthorityType.SALES_REPORT_CREATE);
    },
    HAS_SALES_REPORT_EDIT: (state: LoggedInUser): boolean => {
      return state.authoritiesJson.includes(AuthorityType.SALES_REPORT_EDIT);
    },
    HAS_SALES_REPORT_DELETE: (state: LoggedInUser): boolean => {
      return state.authoritiesJson.includes(AuthorityType.SALES_REPORT_DELETE);
    },
  },
  actions: {
    clearUser(): void {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      this.$reset();
    },
    async reIssueAccessToken(): Promise<void> {
      const newToken = await getNewToken();
      if (newToken) {
        this.saveToken(newToken);
      }
    },
    saveToken(payload: { accessToken: string; refreshToken: string }): void {
      try {
        const jwt = jwt_decode<{
          exp: number;
          id: number;
          loginId: string;
          name: string;
          profileImageUrl: string;
          authoritiesJson: AuthorityTypeString[];
        }>(payload.accessToken);
        window.localStorage.setItem("accessToken", payload.accessToken);
        window.localStorage.setItem("refreshToken", payload.refreshToken);
        this.id = jwt.id;
        this.loginId = jwt.loginId;
        this.name = jwt.name;
        this.profileImageUrl = jwt.profileImageUrl;
        this.authoritiesJson = jwt.authoritiesJson ?? [];
        this.accessToken = payload.accessToken;
      } catch (e: unknown) {
        signOut().then();
      }
    },
    saveProfileImageUrl(val: string) {
      this.profileImageUrl = val;
    },
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage }],
  },
});
