export const AuthorityType = {
  CUSTOMER_CARD_CREATE: "CUSTOMER_CARD_CREATE",
  CUSTOMER_CARD_EDIT: "CUSTOMER_CARD_EDIT",
  CUSTOMER_CARD_DELETE: "CUSTOMER_CARD_DELETE",
  SALES_REPORT_CREATE: "SALES_REPORT_CREATE",
  SALES_REPORT_EDIT: "SALES_REPORT_EDIT",
  SALES_REPORT_DELETE: "SALES_REPORT_DELETE",
};

export type AuthorityTypeString =
  (typeof AuthorityType)[keyof typeof AuthorityType];
