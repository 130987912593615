import router from "@/router";
import { deleteApi, toastAxiosError } from "@/utils/apis";
import { useUserStore } from "@/stores/user";
import stores from "@/stores";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import axios from "axios";
import { API_HOST } from "@/constants/envs";
import type { UserToken } from "@/definitions/types";

export async function routerReplace(path: string): Promise<void> {
  if (router.currentRoute.value.fullPath !== path) {
    await router.replace(path);
  }
}
export async function routerPush(path: string): Promise<void> {
  if (router.currentRoute.value.fullPath !== path) {
    await router.push(path);
  }
}

const { clearUser, reIssueAccessToken } = useUserStore(stores);
export async function goSignInPage(): Promise<void> {
  clearUser();
  await routerReplace("/sign-in");
}

export async function signOut(): Promise<void> {
  try {
    await deleteApi("v1/user/sign-out", false);
  } catch (e) {
    console.error(e);
  }
  await goSignInPage();
}

export async function getValidatedAccessToken(): Promise<string> {
  let accessToken = window.localStorage.getItem("accessToken");
  if (!accessToken) {
    await goSignInPage();
    return "";
  }

  try {
    if (
      dayjs((jwt_decode(accessToken) as { exp: number }).exp * 1000).isBefore(
        dayjs()
      )
    ) {
      await reIssueAccessToken();
      accessToken = window.localStorage.getItem("accessToken");
    }
  } catch (e: unknown) {
    await signOut();
  }
  return accessToken ?? "";
}

export async function getValidatedRefreshToken(): Promise<string> {
  const refreshToken = window.localStorage.getItem("refreshToken");
  if (
    !refreshToken ||
    dayjs((jwt_decode(refreshToken) as { exp: number }).exp * 1000).isBefore(
      dayjs()
    )
  ) {
    await goSignInPage();
  }
  return refreshToken ?? "";
}

export async function getNewToken(): Promise<
  | {
      accessToken: string;
      refreshToken: string;
    }
  | undefined
> {
  try {
    const response = await axios
      .create({
        baseURL: API_HOST,
        headers: {
          contentType: "application/json",
          AuthorizationR: await getValidatedRefreshToken(),
        },
      })
      .get<UserToken>("api/v1/user/new-token");
    return response.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status || 500;
      if (statusCode === 401 || e.message === "Invalid token specified!") {
        await goSignInPage();
      } else if ([403, 404, 500].includes(statusCode)) {
        toastAxiosError(e.message);
      } else {
        console.warn(`Missing Status Code: ${statusCode}`);
        toastAxiosError(e.message);
      }
    } else {
      console.error(e);
    }
  }
}
