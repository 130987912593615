<template>
  <component :is="currentLayout" v-if="isRouterLoaded">
    <router-view />
  </component>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import DefaultLayout from "@/views/layouts/DefaultLayout.vue";
import SimpleLayout from "@/views/layouts/SimpleLayout.vue";
import { useRoute } from "vue-router";

const layouts = {
  default: DefaultLayout,
  simple: SimpleLayout,
};
const route = useRoute();
const isRouterLoaded = computed((): boolean => route.name !== null);
const currentLayout = computed(() => {
  if (route.meta?.layout === "simple") {
    return layouts.simple;
  } else {
    return layouts.default;
  }
});
</script>
