<template>
  <el-header class="header-container">
    <div @click="routerPush('/')">
      <img src="@/assets/images/service_logo.png" />
    </div>
    <div class="username-div">
      <div class="username" @click="visible = !visible">
        {{ name ?? "사용자" }}
      </div>
      <el-popover
        class="popover-box"
        :visible="visible"
        placement="bottom"
        :width="160"
      >
        <div class="popover-options">
          <div @click="profile">Profile</div>
          <div @click="changePasswordPage">Change Password</div>
          <div @click="signOut">Sign Out</div>
        </div>
        <template #reference>
          <el-avatar :size="30">
            <img
              :src="
                profileImageUrl ??
                'https://cdn.aims3.link/common/empty-profile.png'
              "
            />
          </el-avatar>
        </template>
      </el-popover>
    </div>
  </el-header>
</template>

<script setup lang="ts">
import { routerPush, signOut } from "@/utils/commands";
import { ref } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

const { name, profileImageUrl } = storeToRefs(useUserStore());
const visible = ref(false);
function profile() {
  routerPush("profile-general");
  visible.value = false;
}
function changePasswordPage() {
  routerPush("profile-change-password");
  visible.value = false;
}
</script>

<style scoped>
.popover-options div:nth-child(1),
.popover-options div:nth-child(2) {
  margin-bottom: 10px;
}
.popover-options div:hover {
  cursor: pointer;
}
</style>
